@import 'src/assets/common.module';

.document-bar {
  padding: 3px;
  border-radius: 12px;
  position: sticky;
  height: 44px;
  width: 100%;
  margin: 10px 0 10px 0;
  bottom: 10px;
  display: flex;
  vertical-align: middle;
  gap: 10px;
  background-color: $dark-gray-text-color;
  z-index: 1301;
}
