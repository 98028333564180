@import 'src/assets/common.module';

.clause-item-related-docs {
  padding: 0 10px 0 22px;
  width: 100%;
  .header {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }
  .chip {
    font-size: 12px;
    height: 20px;
    margin: 2px;
    background-color: $gray-2;
  }
  .chip-label {
    color: $mid-gray;
  }

  .text {
    white-space: normal;
  }
}
