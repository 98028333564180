@import 'src/assets/common.module';

@mixin button-reset {
  text-transform: none;
  font-size: 0.75em;
  font-weight: bold;
  line-height: 2;
}

div.admin-panel__manage-users-groups {
  margin-top: 35px;
  padding: 24px 30px 8px 30px;
  display: flex;
  justify-content: space-between;
}
.admin-panel {
  & div.MuiDataGrid-columnHeader div.MuiDataGrid-columnSeparator svg.MuiDataGrid-iconSeparator {
    display: block;
  }
}

div.admin-panel__manage-users-groups-filters {
  display: block;
  margin: 0 0 0 10px;

  & div.admin-panel__manage-users-groups-criteria {
    & .MuiTextField-root {
      margin: 0 0 5px 17px;
    }

    & .MuiOutlinedInput-root {
      height: 35px;
      border-radius: 12px;
      color: $mid-gray;

      &.filter-values-selected {
        background: $counter-lighter-red;
        color: $dark-red;
      }

      & .MuiSelect-select {
        & strong {
          text-overflow: ellipsis;
          overflow: hidden;
          vertical-align: bottom;
        }
      }
    }

    & div {
      width: 230px;
    }
  }

  & div.admin-panel__buttons {
    & .MuiButton-root {
      @include button-reset;
      border-radius: 12px;
      height: 3.75em;
      margin-right: 17px;
    }
  }
}

.manage-users-groups-bulk-operations {
  display: flex;
}

div.chip-status {
  background-color: $gray;
  color: $mid-gray;
  font-weight: bold;

  &--active {
    background-color: $lighter-green;
    color: $light-green;
    font-weight: bold;
  }
}

.edit-users-group-form__attention {
  width: 266px;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  margin: auto;

  & p {
    color: $dark-gray-text-color;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }

  & .warning {
    color: $orange;
    width: 44px;
    height: 44px;
  }
}
