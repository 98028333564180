@import 'src/assets/common.module';
.document-compare {
  &.MuiGrid-item {
    display: flex;
    flex-direction: column;
  }

  .file-viewer {
    width: 100%;
    height: 100%;
  }

  &.MuiGrid-root {
    border: 2px solid $lightest-gray;
  }
}

.document-compare-header {
  height: 60px;
  position: relative;
  background: $lightest-gray;
  flex-shrink: 0;

  & .MuiToggleButtonGroup-root.buttons-tabs {
    position: absolute;
    top: 12px;
    left: 10px;
  }
}

.document-compare-menu {
  height: 24px;
  position: absolute;
  top: 16px;
  left: 330px;
  padding: 5px 5px 2px 3px;
  display: flex;
  align-items: center;

  & .MuiButtonBase-root {
    color: inherit;
    line-height: inherit;
    text-transform: none;
    font-size: 12px;

    &:hover {
      border: 0;
    }
  }
}

.document-compare-doc-info-header {
  display: flex;
  justify-content: space-between;
  position: relative;

  & div.document-info-header {
    margin: 20px 10px 10px 15px;
    width: 100%;

    & .file-name-text {
      max-width: 95%;
    }
  }
}

span.toggle-content-switch {
  position: absolute;
  top: 10px;
  right: 15px;
}

.document-compare-window {
  height: 100%;
  width: 100%;
  max-height: calc(100% - 170px);

  &.metadata-list {
    overflow-y: scroll;
  }
}

.full-height {
  height: 100%;
}

.drag-icon {
  position: absolute;
  z-index: 1000;
  margin-left: 240px;
  margin-top: 18px;
  cursor: pointer;
}
