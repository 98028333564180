@import 'src/assets/common.module';

.confirmation-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  min-height: 168px;
  background: $white;
  border-radius: 12px;
  outline: none;
  overflow: hidden;

  & .confirmation-modal-header {
    padding: 24px 23px 0;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;
    color: $mid-gray;

    & .confirmation-modal-title {
      font-weight: bold;
      font-size: 14px;
      line-height: 30px;
      color: $dark-gray-text-color;
    }

    & .confirmation-modal-message {
      padding: 0 0 25px 0;
    }
  }

  & .confirm-button,
  & .cancel-button {
    font-size: 14px;
    font-weight: bold;
    padding-top: 11px;
    padding-bottom: 10px;
    height: 45px;
    width: 188px;
    margin-bottom: 23px;

    &:hover {
      border: 1.5px solid $light-gray;
    }
  }

  & .confirm-button {
    color: $white;
    background-color: #8e0038;
    margin-right: 24px;

    &:disabled {
      background-color: $gray-mui-disabled-button-background;
    }

    &:hover {
      background-color: $mid-red-2;
    }
  }

  & .cancel-button {
    color: $mid-gray;
    margin-left: 24px;
  }
}
