@import 'src/assets/common.module';

.form-warning {
  color: $orange;

  svg {
    width: 14px;
    height: 14px;
    margin-right: 3px;
    vertical-align: middle;
  }
}

.form-notice {
  svg {
    font-size: 14px;
    margin-right: 10px;
    color: $light-gray;
    float: right;
    cursor: pointer;
  }
}

.form-notice-tooltip {
  white-space: pre-line;
}

.custom-text-field-counter {
  text-align: right;
  font-size: 12px;
  line-height: 16px;
  color: $mid-gray;
  margin-bottom: 24px;
}

.label {
  font-size: 0.8em;
  font-weight: bold;
  color: $mid-gray;
  margin: 5px 0;
  padding-left: 10px;
}

.guide-container {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.menu-item--parent-documents {
  span {
    small {
      display: block;
      color: $mid-gray-2;
    }
  }
}
