@import 'src/assets/common.module';

.submit-modal {
  .error-icon,
  .warning-icon .success-icon {
    text-align: center;

    & svg {
      width: 28px;
      height: 28px;
    }
  }

  .success-icon {
    color: $green;
  }

  .error-icon {
    color: $light-red;
  }

  .warning-icon {
    color: $orange;
  }

  .modal-info {
    font-size: 14px;
    line-height: 24px;
    color: $dark-gray-text-color;
    margin: 10px 0 16px;
    text-align: center;
  }

  .duplicate-list,
  .error-list {
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    color: $dark-gray-text-color;
    padding: 10px;
    margin: 10px 0 16px;
    text-align: left;
  }

  .duplicate-list {
    background-color: $lighter-orange;
  }

  .error-list {
    background-color: $lighter-red;
  }
}
