@import "src/assets/common.module";

html {
  background-color: $gray;

}

#root {
  background-color: $gray;
}
