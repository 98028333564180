@import 'src/assets/common.module';

.mars-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 440px;
  border: 1.5px solid $gray;
  border-radius: 12px;
  background-color: $white;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  outline: none;

  & .modal-title {
    height: 24px;
    color: $dark-gray-text-color;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 24px;
    width: 100%;
    margin-bottom: 8px;
  }

  & .rectangle {
    margin-top: 8px;
    height: 1px;
    width: 100%;
    border-top: 1px solid $lighter-gray;
  }

  & .modal-description {
    color: $mid-gray;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 20px;
    max-height: calc(100% - 65px);
  }

  & button.dismiss {
    border-radius: 12px;
    height: 45px;
    width: 389px;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.35px;
    line-height: 24px;
    text-align: center;
    text-transform: none;
  }

  & button.dismiss:hover {
    background-color: $dark-red;
    margin-left: auto;
  }
}
