@import 'src/assets/common.module';

.panel-card {
  border: 2px solid $light-gray;
  color: $dark-gray-text-color;
  background-color: white;
  height: 140px;
  width: 240px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  margin-right: 56px;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;

  &.selected {
    border: 2px solid $dark-red;
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }

  &:hover {
    border: 2px solid $dark-red;
  }

  &:last-child {
    margin-right: 0;
  }

  svg {
    margin-bottom: 10px;
  }
}
