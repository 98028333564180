@import 'src/assets/common.module';

.search-field-wrapper {
  display: flex;
  margin-bottom: 4px;
  width: 100%;

  .search-field__after-icon {
    width: 45px;

    .MuiIconButton-root {
      height: 45px;
    }
  }
}

div.search-field {
  position: static;
  width: 100%;

  &--selected:after {
    content: '◀';
    position: absolute;
    right: -4px;
    color: $light-gray;
    line-height: 45px;
  }

  & button.search-field__close-end-icon--visible {
    border-radius: 0 0 0 0;
    height: 43px;
    right: -13px;
  }

  & button.search-field__search-end-icon {
    border-radius: 0 11px 11px 0;
    height: 43px;
    right: -13px;

    &--active {
      background-color: $dark-red;
      border-radius: 0 11px 11px 0;
      height: 43px;
      right: -13px;

      & svg {
        color: $white !important;
      }
    }
  }

  &.MuiTextField-root {
    margin-right: 5px;

    & .MuiOutlinedInput-root {
      border-radius: 12px;
    }

    & .MuiSvgIcon-root {
      color: $mid-gray;
      margin: 0 5px 0 3px;
    }

    & .MuiInputBase-input {
      color: $dark-gray-text-color;
      font-size: 14px;
      height: 2em;
    }
  }
}
