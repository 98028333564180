@use './FileViewer.module' as FileViewer;
@import 'src/assets/common.module';

$toolbar-height: 40px;

.file-viewer {
  &--html {
    iframe {
      display: block;
      border: none;
      width: 100%;
      height: calc(100% - $toolbar-height);
      background: #ffffff;
    }
  }

  &--txt {
    &__content {
      overflow: auto;
      width: 100%;
      height: calc(100% - $toolbar-height);
      background: #ffffff;
      padding: 8px;

      div {
        transform-origin: 0 0;
        white-space: pre-line;
      }

      &__text--added,
      ins {
        background-color: #ceede7 !important;
      }

      &__text--removed,
      del {
        background-color: #ffc9cb !important;
      }
    }
  }

  &--document {
    overflow: scroll;
    height: calc(100% - $toolbar-height);
    width: 100%;
  }

  &--navigation {
    display: flex;
    justify-content: center;
    height: $toolbar-height;
  }

  mark {
    background: FileViewer.$mark-background;

    &.current {
      background: FileViewer.$mark-current-background;
      color: FileViewer.$mark-current-color;
    }
  }
}
