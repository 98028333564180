@import 'src/assets/common.module';

.excluded-words-input {
  border: 1.5px solid $gray;
  padding: 3px 4px;
  border-radius: 12px;

  & .MuiChip-root {
    border-radius: 8px;
    margin-right: 6px;

    & .MuiChip-deleteIcon {
      display: none;
    }

    &:hover {
      background: $counter-lighter-red;
      color: $dark-red;

      & .MuiChip-deleteIcon {
        display: initial;
      }
    }
  }

  & input {
    width: 230px;
    border: none;
    font-family: inherit;
    font-size: 12px;
    outline: none;
  }
}
