@import 'src/assets/common.module';

.admin-panel-title {
  height: 24px;
  width: 198px;
  color: $dark-gray-text-color;
  font-family: $font-family;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 24px;
  margin: 0 0 0 19px;
}

.slider {
  position: relative;
  display: flex;
  justify-content: center;

  &::after {
    content: '';
    position: absolute;
    bottom: 0px;
    left: 0;
    right: 0;
    height: 1.5px;
    background-color: $dark-red;
    z-index: 1;
  }

  &--line {
    position: relative;
    height: 1.5px;
    background-color: $dark-red;
    margin-top: 24px;
    margin-bottom: 0px;
    width: 832px;

    svg {
      position: absolute;
      top: -11px;
      transition: left 0.2s ease;
      transform: translateX(-50%);

      &.selected-0 {
        left: 120px;
      }

      &.selected-1 {
        left: calc(0.5 * 832px);
      }

      &.selected-2 {
        left: 712px;
      }
    }
  }
}
