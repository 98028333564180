.report-details-summary {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  min-height: 60px;
  overflow: auto;
  table {
    width: 100%;
    table-layout: fixed;
  }
  & .label {
    width: 90px;
    vertical-align: top;
    color: #333333;
    font-size: 14px;
    font-weight: bold;
  }
  & .value {
    overflow-wrap: anywhere;
    font-size: 14px;
  }
}
