@import 'src/assets/common.module';

.error-modal-wrapper.MuiModal-root {
  z-index: 1400;
}

.error-modal {
  margin: 0 0 15px 0;

  & .description {
    margin: 14px 0 3px 0;
    color: $mid-gray;
    font-size: 0.9rem;
    line-height: 1.1;
  }
}
