@import 'src/assets/common.module';

div.spreadsheet-view {
  min-height: 100vh;
  border-radius: 12px;

  &.fullscreen {
    position: absolute;
    top: 54px;
    left: 0;
    right: 0;
    z-index: 1;
    border-radius: 0;
  }
}

.top-grid-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 32px;
  height: 37px;
}
