@import 'src/assets/common.module';

.MuiDataGrid-panel {
  & div.MuiDataGrid-panelFooter,
  & div.MuiDataGrid-panelHeader {
    display: none;
  }

  & .MuiDataGrid-columnsPanel {
    margin: 10px 8px;
  }

  & .MuiPaper-elevation {
    border: 1.5px solid $light-gray;
    border-radius: 12px;
  }

  & .MuiFormControlLabel-label {
    color: $dark-gray-text-color;
    font-size: 14px;
  }

  & .Mui-disabled {
    display: none;
  }
}

div.documents-list-grid {
  color: $mid-gray;
  padding: 16px 40px;
  height: 100vh;
  border-radius: 12px;
  background-color: $white;
  border: none;

  @include sticky-header;

  & .MuiDataGrid-row {
    &.error {
      background-color: $top-light-red;
      border: 1px solid $light-red;
    }
  }

  & div.MuiDataGrid-main {
    margin: 0 10px 0 0;
  }

  & .documents-list-grid-toolbar {
    position: absolute;
    right: 15px;
    top: 23px;
    width: 24px;

    & .documents-list-settings {
      min-width: 24px;
      text-align: center;

      & .MuiButton-startIcon {
        margin: 0;

        & svg {
          color: $mid-gray;
          height: 24px !important;
          width: 24px !important;
        }
      }
    }
  }

  & .MuiDataGrid-cell:focus,
  & .MuiDataGrid-cell:focus-within,
  & .MuiDataGrid-columnHeader:focus,
  & .MuiDataGrid-columnHeader:focus-within {
    outline: none;
  }

  & .active-status {
    &.active {
      color: $green;
    }

    &.not-active {
      color: $dark-red;
    }
  }

  & .MuiDataGrid-columnHeader {
    height: 16px;
    letter-spacing: 0;
    line-height: 16px;
    text-transform: uppercase;

    & .MuiDataGrid-columnHeaderTitle {
      font-weight: bold;
      font-size: 12px;
    }

    & svg.MuiDataGrid-iconSeparator {
      display: none;
    }
  }

  & .MuiCheckbox-root .MuiSvgIcon-fontSizeMedium {
    color: $light-gray;
    width: 18px;
    height: 18px;
    border-radius: 13px;
  }

  & .Mui-checked {
    &.MuiCheckbox-root .MuiSvgIcon-fontSizeMedium {
      color: $dark-red;
      width: 18px;
      height: 18px;
    }
  }

  & .country {
    margin-left: 4px;
  }
}

div.documents-count {
  margin: 24px 0 16px 0;
  min-height: 24px;
  background-color: $gray;
  color: $mid-gray;

  & .documents-count-error {
    color: $mid-red;
  }

  .search-queries {
    margin: 8px 0 0 0;

    &-item {
      border-radius: 8px;
      display: inline-block;
      height: 32px;
      line-height: 32px;
      font-size: 14px;
      color: $dark-gray-text-color;
      background: white;
      margin: 0 10px 4px 0;
      padding: 1px 8px;

      span:before {
        content: '|';
        padding: 0 7px;
      }
    }
  }
}

div.documents-list {
  margin: 0;
  min-height: 100vh;
  border-radius: 12px;
  background-color: $white;
}

div.MuiTablePagination-root {
  color: $mid-gray;
  margin-top: 25px;

  & .MuiInputBase-root {
    margin-right: 0;
  }

  & .MuiTablePagination-selectLabel {
    height: 16px;
    width: 93px;
    color: $mid-gray;
    font-family: $font-family;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }

  & div.MuiTablePagination-select {
    box-sizing: border-box;
    height: 34px;
    border: 1.5px solid $light-gray;
    border-radius: 12px;
    background-color: $white;
    padding-left: 15px;
    padding-top: 6px;
  }
}
