@import 'src/assets/common.module';

$bar-height: 44px;
$compare-bar-height: 54px;

.compare-modal-root.MuiModal-root {
  z-index: 1302;
}

.compare-modal {
  width: 100%;
  height: 100%;
  top: 0;
  border-radius: 0;
  min-width: 1225px;

  &--caluse {
    .MuiGrid-container {
      height: calc(100% - $compare-bar-height);
      overflow: hidden;
    }
  }
}

.document-compare-wrap {
  iframe {
    pointer-events: none;
  }
  &:hover iframe {
    pointer-events: initial;
  }

  .document-compare {
    &.MuiGrid-item {
      width: 50%;
      height: 100%;
    }
  }

  &.MuiGrid-container {
    height: calc(100% - $compare-bar-height);
    overflow: hidden;
  }

  &.document-compare-three {
    .document-compare {
      &.MuiGrid-item {
        width: 33%;
      }
    }

    &.MuiGrid-container {
      height: calc(100% - $bar-height - $compare-bar-height);
      overflow: hidden;
    }
  }

  &.document-compare-multiple {
    &.MuiGrid-container {
      & > div:nth-child(2) > div {
        border-left: 25px solid $lightest-gray;
      }

      & > div:nth-child(3) > div {
        border-right: 25px solid $lightest-gray;
      }
    }
  }
}

.document-compare-drop {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(205, 205, 205, 0.3);
    z-index: 999;
  }
}

.document-compare-nav {
  &_prev,
  &_next {
    position: absolute;
    top: 46%;
    cursor: pointer;
    z-index: 999;
  }

  &_prev {
    left: 33.3%;
  }

  &_next {
    right: 0;
  }
}

.document-compare-bar {
  display: flex;
  background: $light-gray-2;
  padding: 3px 3px 3px 0;
  gap: 3px;
  height: $bar-height;

  button:nth-child(1) {
    background: #ceede7;
  }

  button:nth-child(2),
  button:nth-child(3) {
    background: #f8cce0;
  }

  .dragging {
    opacity: 0.5;
  }
}
