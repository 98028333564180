@import 'src/assets/common.module';

div.generate-confirmation__modal {
  width: 440px;
}

.generate-confirmation__modal-message {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: center;
  width: 392px;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  margin: auto;

  & .success-icon {
    width: 44px;
    color: $green;
  }
}

div.generate-confirmation__modal-buttons {
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  align-items: center;
  gap: 8px;

  & .cancel-button {
    margin-left: 0;
    width: 100%;
  }

  & .confirm-button {
    width: 100%;
  }
}
